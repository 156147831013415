import React from 'react'
import {
  Pagination as BootstrapPagination,
  PaginationItem,
  PaginationLink,
} from '~/bootstrap/components/pagination'
import {Link} from '~/common/components/link'
import {createUrl} from '../../util'
import {KenticoPageCollectionData} from './data'

interface Props {
  count: number
  index: number
  page: KenticoPageCollectionData
}

/**
 * Render pagination.
 * @return React component
 */
export const Pagination = ({count, index, page}: Props) => {
  const min = Math.max(0, index === count - 1 ? count - 3 : index - 1)
  const max = Math.min(count - 1, index === 0 ? 2 : index + 1)
  const items = Array.from({length: max - min + 1}).map((_, i) => {
    const idx = i + min
    return (
      <PaginationItem key={idx} active={idx === index}>
        <PaginationLink tag={Link} to={createUrl(page, {page: idx})}>
          {idx + 1}
        </PaginationLink>
      </PaginationItem>
    )
  })
  return (
    <BootstrapPagination>
      <PaginationItem disabled={count <= 3 || index <= 1}>
        <PaginationLink first tag={Link} to={createUrl(page, {page: 0})} />
      </PaginationItem>
      {items}
      <PaginationItem disabled={count <= 3 || count - index < 3}>
        <PaginationLink
          last
          tag={Link}
          to={createUrl(page, {page: count - 1})}
        />
      </PaginationItem>
    </BootstrapPagination>
  )
}
