import {getImage, IGatsbyImageData} from 'gatsby-plugin-image'
import {BgImage} from 'gbimage-bridge'
import {toString} from 'lodash'
import React from 'react'
import styled from 'styled-components'
import {
  Card as BootstrapCard,
  CardBody,
  CardText as BootstrapCardText,
  CardTitle,
} from '~/bootstrap/components/card'
import {Customize} from '~/common/components/customize'
import {Link} from '~/common/components/link'
import {createUrl, validImage} from '../../util'
import {KenticoPageCollectionChildData} from './data'

/** Kentico type data. */
export {KenticoPageCollectionChildData}

interface Props {
  data: KenticoPageCollectionChildData
}

const DESCRIPTION_HEIGHT = 6
const HEADER_HEIGHT = 4

const Card = styled(BootstrapCard)`
  margin-bottom: ${({theme}) => theme.spacers[5]};
`

// TODO Remove -webkit once relevant items are standardized
const CardText = styled(BootstrapCardText)`
  height: ${({theme}) =>
    DESCRIPTION_HEIGHT *
    theme.text.lineHeight *
    parseFloat(theme.text.fontSize.base)}rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: ${DESCRIPTION_HEIGHT};
  -webkit-box-orient: vertical;
`

const CardImage = styled(BgImage)`
  height: 215px;
`

// TODO Remove -webkit once relevant items are standardized
const Header = styled.h2`
  font-size: ${({theme}) => theme.text.fontSize.h4};
  line-height: ${({theme}) => theme.text.lineHeight};
  height: ${({theme}) =>
    HEADER_HEIGHT *
    theme.text.lineHeight *
    parseFloat(theme.text.fontSize.h4)}rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: ${HEADER_HEIGHT};
  -webkit-box-orient: vertical;
  -webkit-box-pack: center;

  @media (max-width: ${({theme}) => theme.breakpoints.max.sm}) {
    font-size: ${({theme}) => theme.text.fontSize.mobile.h4};
    height: ${({theme}) =>
      HEADER_HEIGHT *
      theme.text.lineHeight *
      parseFloat(theme.text.fontSize.mobile.h4)}rem;
  }
`

/**
 * Render page collection child from Kentico data.
 * @return React component
 */
export const KenticoPageCollectionChild = ({data}: Props) => {
  let alt
  let image
  if (validImage(data.elements.image)) {
    alt = toString(data.elements.image.value[0].description)
    image = data.elements.image.value[0].file.childImageSharp.gatsbyImageData
  }
  let gatsbyImageData = image as IGatsbyImageData;
  let pluginImage = getImage(gatsbyImageData);
  return (
    <Card key={data.system.id}>
      <CardImage alt={alt} image={pluginImage} />
      <CardBody>
        <CardTitle>
          <Header>{data.elements.blogTitle.value}</Header>
        </CardTitle>
        <CardText>{data.elements.description.value}</CardText>
        <Customize component={Link} textColor="secondary" to={createUrl(data)}>
          <strong>Read More &gt;</strong>
        </Customize>
      </CardBody>
    </Card>
  )
}
