import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import React from 'react'
import {KenticoGlobalScriptData} from '~/kentico/components/global-script'
import {
  KenticoPageCollection,
  KenticoPageCollectionData,
} from '~/kentico/components/page-collection'
import {KenticoPageCollectionChildData} from '~/kentico/components/page-collection-child'
import {LinkedItems, linkedItems} from '~/kentico/util'
import {CookieError} from '~/common/components/cookie-error'
import {GatsbyLayout} from '../components/layout'
import {useDecode} from '../util'

const Props = IO.type({
  data: IO.type({
    globalScripts: KenticoGlobalScriptData,
    items: LinkedItems(KenticoPageCollectionChildData),
    page: KenticoPageCollectionData,
  }),
  pageContext: IO.type({
    count: IO.number,
    index: IO.number,
  }),
})

/** Query from template. */
export const query = graphql`
  query($id: String!, $limit: Int!, $skip: Int!) {
    page: kontentItemPageCollection(id: {eq: $id}) {
      ...KenticoPageCollection
    }
    items: allKontentItemPageCollectionItem(
      filter: {elements: {parent: {value: {elemMatch: {id: {eq: $id}}}}}}
      sort: {fields: [elements___published_date___value], order: DESC}
      limit: $limit
      skip: $skip
    ) {
      nodes {
        ...KenticoPageCollectionChild
      }
    }
    ...KenticoGlobalScripts
  }
`

const PageCollection = (props: unknown) => {
  const {
    data: {items, ...data},
    pageContext: {count, index},
  } = useDecode(Props, props)

  return (
    <GatsbyLayout contentId={data.page.system.id}>
      <CookieError />
      <KenticoPageCollection
        {...data}
        count={count}
        index={index}
        items={linkedItems(items)}
      />
    </GatsbyLayout>
  )
}

/** Page collection. */
// eslint-disable-next-line import/no-default-export
export default PageCollection
