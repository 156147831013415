import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import {KenticoFluidImageData} from '../fluid-image'
import {KenticoPageLinkData} from '../page-link'

/** Page collection child. */
export const KenticoPageCollectionChildData = IO.intersection(
  [
    KenticoPageLinkData,
    IO.type({
      elements: IO.type({
        blogTitle: IO.type({
          value: IO.string,
        }),
        description: IO.type({
          value: IO.string,
        }),
        image: IO.type({
          value: IO.array(KenticoFluidImageData),
        }),
      }),
      id: IO.string,
    }),
  ],
  'KenticoPageCollectionChild',
)

/** Data type. */
export interface KenticoPageCollectionChildData
  extends IO.TypeOf<typeof KenticoPageCollectionChildData> {}

/** Fragment helper. */
export const fragment = graphql`
  fragment KenticoPageCollectionChild on Node {
    ... on kontent_item_page_collection_item {
      ...KenticoPageLink
      elements {
        description: metadata__description {
          value
        }
        image {
          value {
            ...KenticoFluidImage
          }
        }
        blogTitle: title {
          value
        }
      }
      id
    }
  }
`
