import React from 'react'
import styled from 'styled-components'
import {Col, Container, Row} from '~/bootstrap/components/layout'
import {Customize} from '~/common/components/customize'
import {GatsbySEO} from '~/gatsby/components/seo'
import {createReplace} from '../../renderer'
import {linkedItems} from '../../util'
import {KenticoBodyItem} from '../body-item'
import {KenticoGlobalScriptData} from '../global-script'
import {
  KenticoPageCollectionChild,
  KenticoPageCollectionChildData,
} from '../page-collection-child'
import {KenticoPageCollectionData} from './data'
import {Pagination} from './pagination'

interface Props {
  count: number
  index: number
  page: KenticoPageCollectionData
  items: KenticoPageCollectionChildData[]
  globalScripts: KenticoGlobalScriptData
}

const ItemsContainer = styled(Container)`
  padding-top: ${({theme}) => theme.spacers[5]};
  padding-bottom: ${({theme}) => theme.spacers[3]};
`

const PaginationContainer = styled(Container)`
  padding-bottom: ${({theme}) => theme.spacers[4]};
`

const PAGINATION_FLEX = {
  justify: 'center',
} as const

/** Kentico type data. */
export {KenticoPageCollectionData}

/**
 * Render page collection from Kentico data.
 * @return React component
 */
export const KenticoPageCollection = ({
  count,
  globalScripts,
  index,
  items,
  page,
}: Props) => {
  const children = items.map(subPage => (
    <Col key={subPage.system.id} lg="4">
      <KenticoPageCollectionChild data={subPage} />
    </Col>
  ))

  return (
    <>
      <GatsbySEO
        description={page.elements.description.value}
        globalScripts={globalScripts}
        page={page}
        pageScripts={page.elements.scripts}
        replace={createReplace}
        robots={page.elements.robots}
        title={page.elements.title.value}
      />
      <KenticoBodyItem data={linkedItems(page.elements.preBody)} />
      <ItemsContainer>
        <Row>{children}</Row>
      </ItemsContainer>
      <Customize component={PaginationContainer} flex={PAGINATION_FLEX}>
        <Pagination count={count} index={index} page={page} />
      </Customize>
      <KenticoBodyItem data={linkedItems(page.elements.postBody)} />
    </>
  )
}
